import React from 'react';
import { Link } from 'react-router-dom';
import './error.css'

const Error = () => {
  return (
    <div id="main">
      <div class="fof">
        <h1>Error 404</h1>
        <p>Page Not Found</p>
        <Link to='/' className='btn'>Back Home </Link>
      </div>
    </div>
  );
};

export default Error;
