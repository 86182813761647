import React from 'react';
import { Link } from 'react-router-dom'
import "./Movie.css";
import {IMAGE_API, NO_IMAGE_API} from './Api';

const setVoteClass = (vote) => {
  if (vote >= 8){
    return 'green';
  }
  else if (vote >= 6){
    return "orange";
  }
  else {
    return "red";
  }
}

function Movie(props) {
  return (
    <Link to={`/movies/${props.id}`} key={props.id} className="movie">
      <img src={(props.poster_path ? (IMAGE_API + props.poster_path) : NO_IMAGE_API)} alt={props.title}/>
      <div className="movie-info">
        <h3>{props.title}</h3>
        <span className="tag" style={{color: setVoteClass(props.vote_average)}}>
          {props.vote_average}
        </span>
      </div>

      <div className="movie-over">
        <p><span style={{fontWeight:"bold"}}>Release Date: </span>{props.release_date}</p>
        <p><span style={{fontWeight:"bold"}}>Vote Count: </span>{props.vote_count}</p>
      </div>
    </Link>
  )
}

export default Movie;
