
import React, {useState, useEffect} from 'react';
import { useParams, Link } from 'react-router-dom'
import './SingleMovie.css';
import {IMAGE_API, NO_IMAGE_API} from './Api';

function SingleMovie () {
  const [movie, setMovie] = useState([]);

  const { id } = useParams();
  const movie_link = `https://api.themoviedb.org/3/movie/${id}?api_key=${process.env.REACT_APP_OPEN_MOVIEDB_API_KEY}`;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })

  useEffect(() => {
    const fetchMovie = () => {
      fetch(movie_link)
        .then(res => res.json())
        .then(data => {
          setMovie(data);
        });
      };
    fetchMovie();
  }, [movie_link]);

  function getString(array){
    const list = [];
    if (array !== undefined){
      array.forEach(item => {
        list.push(item.name);
      })
    }
    return list.toString();
  }

  return (
    <div className="container">
      <div className="image">
      <img src={(movie.poster_path ? (IMAGE_API + movie.poster_path) : NO_IMAGE_API)} alt={movie.title} className="image"/>
      </div>
      <div className="text">
        <h2>{movie.title}</h2>
        <p>{movie.overview}</p>
        <p><span style={{fontWeight:"bold"}}>Release Date: </span>{movie.release_date}</p>
        <p><span style={{fontWeight:"bold"}}>Runtime: </span>{movie.runtime} mins</p>
        <p><span style={{fontWeight:"bold"}}>Budget: </span> {formatter.format(movie.budget)}</p>
        <p><span style={{fontWeight:"bold"}}>Revenue: </span> {formatter.format(movie.revenue)}</p>
        <p><span style={{fontWeight:"bold"}}>Genre: </span>
          {(movie.genres !== undefined) && getString(movie.genres)}
        </p>
        <p><span style={{fontWeight:"bold"}}>Production Companies: </span>
          {(movie.production_companies !== undefined) && getString(movie.production_companies)}
        </p>
        <Link to='/' className='btn'>back to movies
        </Link>
      </div>
    </div>
  )
}

export default SingleMovie;
