import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from './components/Home'
import SingleMovie from './components/SingleMovie'
import Error from './components/error'

function App() {
  return <Switch>
    <Route path="/" exact>
      <Home />
    </Route>
    <Route path="/movies/:id" children={<SingleMovie/>}/>
    <Route path="*">
      <Error />
    </Route>
  </Switch>
}

export default App
