import React, {useEffect, useState} from 'react';
import Movie from './Movie';
import {FEATURED_API, SEARCH_API} from './Api';

function Home () {
  const [movies, setMovies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getMovies(FEATURED_API);
  }, []);

  function getMovies(api) {
    fetch(api)
      .then(res => res.json())
      .then((data) => {
        setMovies(data.results);
      });
  }

  const handleOnSubmit = ((event) => {
    event.preventDefault();

    if (searchTerm){
      getMovies(SEARCH_API + searchTerm);
      setSearchTerm("");
    }
  });

  const handleOnChange = ((event) => {
    setSearchTerm(event.target.value);
  });

  return (
    <React.Fragment>
      <header>
        <form onSubmit={handleOnSubmit}>
          <input
            className="search"
            type="search"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleOnChange}
          />
        </form>
      </header>
      <div className="movie-container">
        {movies.length > 0 && movies.map(movie =>
          <Movie key={movie.id} {...movie}/>
        )}
      </div>
    </React.Fragment>
  )
}

export default Home;
